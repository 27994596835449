
import {
  Component, Mixins, Prop, Watch,
} from 'vue-property-decorator';
import { Doughnut } from 'vue-chartjs';

@Component({
  extends: Doughnut,
})
export default class DoughnutChart extends Mixins(Doughnut) {
  @Prop() name!: string;
  @Prop() data!: [];
  @Prop() color!: string;

  mounted() {
    this.initRenderChart();
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  @Watch('isDarkMode')
  initRenderChart() {
    this.renderChart({
      labels: [this.name],
      datasets: [
        {
          label: this.name,
          data: this.data,
          borderColor: getComputedStyle(document.documentElement).getPropertyValue(this.color),
          backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(this.color),
        },
      ],
    },
    {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          boxWidth: 8,
          fontStyle: '500',
          fontColor: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-pure'),
          padding: 20,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    });
  }
}
