

























































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import CreditsLoading from '@/pages/Redaction/components/Credits/CreditsLoading.vue';

import Information from '@/assets/icons/Information.vue';
import CreditsIcon from '@/assets/icons/Credits.vue';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import ActiveModal from '@/share/Util/ActiveModal';

const PHRASE_UNLIMITED_CREDITS = 'Você possui créditos ilimitados.';
const NAME_CREDIT = 'crédito';
const NAME_CREDITS = 'créditos';

@Component({
  components: {
    CreditsLoading,
    Information,
    CreditsIcon,
  },
})
export default class Credits extends Vue {
  @Prop() current!: number;
  @Prop() total!: number;
  @Prop() currentConsumer!: number;
  @Prop() totalConsumer!: number;
  @Prop() isLoading!: boolean;
  @Prop({ default: true }) isShowCreditsConsumed!: boolean;
  @Prop({ default: true }) isShowCreditsSend!: boolean;

  private ActiveModal = new ActiveModal();

  get title() {
    return this.isPlanFree ? 'Créditos free disponíveis' : 'Créditos premium disponíveis';
  }

  get message() {
    if (this.current === null || this.total === null) return 'Não possui créditos!';

    if (this.total > 9999) {
      return PHRASE_UNLIMITED_CREDITS;
    }

    const nameCredits = this.current === 1 ? NAME_CREDIT : NAME_CREDITS;

    return `Você possui ${this.current} ${nameCredits} de ${this.total}.`;
  }

  get messageConsumer() {
    if (this.currentConsumer === null || this.totalConsumer === null) return 'Não possui créditos!';

    if (this.totalConsumer > 9999) {
      return PHRASE_UNLIMITED_CREDITS;
    }

    const nameCredits = this.currentConsumer === 1 ? NAME_CREDIT : NAME_CREDITS;

    return `Você possui ${this.currentConsumer} ${nameCredits} de ${this.totalConsumer}.`;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  handleShowInformation() {
    this.ActiveModal.activeModal('ModalConsumerCredits');
  }
}

