






























































































import {
  Component,
  Mixins,
  Watch,
} from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ModalCreditIsOver from '@/components/Redaction/Modals/ModalCreditIsOver.vue';
import Footer from '@/components/Footer/Footer.vue';
import ThemeWeek from '@/components/ThemeWeek/ThemeWeek.vue';
import Ranking from '@/components/Ranking/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import MyPerformance from './components/MyPerformance/MyPerformance.vue';
import ListDevelopedThemes from './components/ListDevelopedThemes/ListDevelopedThemes.vue';
import ListDevelopedThemesLoading from './components/ListDevelopedThemes/ListDevelopedThemesLoading.vue';
import AccordionRedaction from './components/AccordionRedaction/AccordionRedaction.vue';
import AccordionRedactionLoading from './components/AccordionRedaction/AccordionRedactionLoading.vue';
import TopRedaction from './components/TopRedaction/TopRedaction.vue';
import TopRedactionLoading from './components/TopRedaction/TopRedactionLoading.vue';
import Credits from './components/Credits/Credits.vue';
import FeedbackPermissionRedaction from './components/FeedbackPermissionRedaction/FeedbackPermissionRedaction.vue';

import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

import ActiveModal from '@/share/Util/ActiveModal';
import { setNumber } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import permission from '@/mixins/permission';

import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import {
  ListRedaction as IListRedaction,
  BestTheme as IBestTheme,
  BestRedaction as IBestRedaction,
} from './interfaces/IRedaction';

import {
  Theme as ITheme,
} from './interfaces/IRedactionPreview';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    ModalCreditIsOver,
    MyPerformance,
    ListDevelopedThemes,
    ListDevelopedThemesLoading,
    AccordionRedaction,
    AccordionRedactionLoading,
    TopRedaction,
    TopRedactionLoading,
    Credits,
    Footer,
    FeedbackUser,
    ThemeWeek,
    FeedbackPermissionRedaction,
    RealtimeUsers,
    Ranking,
  },
})
export default class Redaction extends Mixins(permission) {
  private isLoadingListBestTheme = true;
  private isLoadingListRedaction = true;
  private isLoadingDataRedaction = true;

  private listBestTheme!: Array<IBestTheme>;
  private listRedaction!: Array<IListRedaction>;
  private listBestRedaction!: Array<IBestRedaction>;

  private setModal = new ActiveModal();

  created() {
    this.getListBestTheme();
    this.getListRedaction();
    this.getTotalRedactionSend();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get isLoadingDataPage() {
    return this.isLoadingListBestTheme || this.isLoadingListRedaction || this.isLoadingDataRedaction;
  }

  get hasPermission() {
    return this.can(LIST_PERMISSION.REDACTION_CREDITS);
  }

  get getTotalCreditsRedaction(): number | null {
    const total = this.getQuantityPermission(LIST_PERMISSION.REDACTION_CREDITS);

    return total;
  }

  get getRemainingCreditsRedaction() {
    const totalCredits = this.getTotalCreditsRedaction;
    if (!totalCredits) return null;

    const resultCredits = totalCredits - this.totalRedactionMeMonthly;

    return resultCredits < 0 ? 0 : resultCredits;
  }

  get totalRedactionMeMonthly() {
    return Number(this.$store.getters.totalRedactionsMeMonthly);
  }

  get setClassCol() {
    return this.hasPermission
      ? 'col-xl-9 col-md-8 col-12 order-2 order-md-1'
      : 'col';
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  @Watch('getRemainingCreditsRedaction', {
    immediate: true,
  })
  setStoreCanOpenProposals() {
    if (this.getRemainingCreditsRedaction === null) {
      this.$store.commit('setCanOpenProposals', false);
      return;
    }

    this.$store.commit('setCanOpenProposals', this.getRemainingCreditsRedaction > 0);
  }

  @Watch('hasPermission', { immediate: true })
  setPermissionStore() {
    this.$store.commit('setPermissionRedaction', this.hasPermission);
  }

  async getListBestTheme() {
    try {
      this.isLoadingListBestTheme = true;

      const response = await this.RedactionService.getThemesMostPopular();

      this.listBestTheme = response;

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os melhores temas.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingListBestTheme = false;
    }
  }

  async getListRedaction() {
    try {
      this.isLoadingListRedaction = true;

      const response = await this.RedactionService.getRedactionsByCategory();
      const redactionsSimulated: ITheme[] = await this.RedactionService.getThemesOfSimulated();

      const lastPositionCategory = setNumber(response.length + 1);

      const simulationsRedactionsCategoryObject = {
        id: lastPositionCategory,
        themes: redactionsSimulated,
        title: `2.${lastPositionCategory} Temas dos Simulados do Explicaê`,
      };

      this.listRedaction = [
        ...response,
        simulationsRedactionsCategoryObject,
      ];
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a lista de redação.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingListRedaction = false;
    }
  }

  async getTotalRedactionSend() {
    try {
      this.isLoadingDataRedaction = true;
      const response = await this.RedactionService.getTotalRedactionSend();

      if (response?.total_redactions_me_monthly) {
        this.setStoreTotalRedactionSend(response?.total_redactions_me_monthly);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar quantidades de créditos.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingDataRedaction = false;
    }
  }

  setStoreTotalRedactionSend(quantity: number) {
    this.$store.commit('setTotalRedactionsMeMonthly', quantity);
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Redação', to: null },
    ]);
  }

  showModal() {
    this.setModal.activeModal('ModalCreditIsOver');
  }
}
